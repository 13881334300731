import React from "react"
import { Link } from "gatsby"
import { Div, Text, Row, Col, Container, Anchor, Icon } from "atomize"
import CTA from "../cta"

const footerLinks = {
  "Chính sách & Điều khoản": [
    {
      name: "Điều khoản sử dụng",
      link: "/policy",
    },
    {
      name: "Chính sách bảo mật thông tin",
      link: "/data",
    },
    {
      name: "Chính sách giao vận",
      link: "/delivery",
    },
    {
      name: "Chính sách hoàn trả",
      link: "/refund",
    },
    {
      name: "Chính sách thanh toán",
      link: "/payment",
    },
    {
      name: "Chính Sách Bảo Mật & Riêng Tư (English)",
      link: "/privacy",
    },

    // atomize: [
    //   {
    //     name: "Features",
    //     link: "#features",
    //   },
    //   {
    //     name: "Design",
    //     anchor: true,
    //     link: "http://www.atomizedesign.com/",
    //   },
    //   {
    //     name: "Development",
    //     link: "/docs/react/intro",
    //   },
    //   // {
    //   //   name: "Github",
    //   //   anchor: true,
    //   //   link: "https://github.com/Proksh/atomize",
    //   // },
    // ],
    // resources: [
    //   {
    //     name: "Sketch File",
    //     anchor: true,
    //     link: "https://gumroad.com/l/atomize",
    //   },
    //   {
    //     name: "Github",
    //     anchor: true,
    //     link: "https://github.com/Proksh/atomize",
    //   },
    // ],
    // about: [
    //   // {
    //   //   name: "Introduction",
    //   //   link: "/docs/react/intro",
    //   // },
    //   {
    //     name: "Showcase",
    //     link: "/docs/react/showcase",
    //   },
    //   {
    //     name: "Contribute",
    //     link: "/docs/react/contributing",
    //   },
    // ],
    // extras: [
    //   {
    //     name: "Blog",
    //     anchor: true,
    //     link:
    //       "https://hashnode.com/post/getting-started-with-react-atomize-and-gatsby-cjyfgtj0m001obzs19cobmogi",
    //   },
    //   // {
    //   //   name: "Product Hunt",
    //   //   anchor: true,
    //   //   link: "/",
    //   // },
    //   {
    //     name: "Need Help?",
    //     anchor: true,
    //     link: "mailto:proksh@atomizedesign.com",
    //   },
    //   {
    //     name: "Give Feedback",
    //     anchor: true,
    //     link: "mailto:proksh@atomizedesign.com",
    //   },
  ],
  "Đại diện": [
    {
      name: "CEO - Mr.Đỗ Văn Bằng",
      link: "tel:+84327335877",
    },
    {
      name: "SĐT / Zalo: 84 32 7335877",
      link: "tel:+84327335877",
    },
    {
      name: "Email: contact@ucall.asia",
      link: "mailto:contact@ucall.asia",
    },
    {
      name: "Facebook: @ucall.asia",
      link: "https://facebook.com/ucall.asia",
    },
  ],
}

const mediaLinks = [
  // {
  //   icon: "Github",
  //   link: "https://github.com/",
  // },
  // {
  //   icon: "Behance",
  //   link: "https://www.behance.net/",
  // },
  // {
  //   icon: "Dribbble",
  //   link: "https://dribbble.com/",
  // },
  // {
  //   icon: "Twitter",
  //   link: "https://twitter.com/",
  // },
  // {
  //   icon: "Linkedin",
  //   link: "https://www.linkedin.com/in/",
  // },
]

const Footer = () => {
  return (
    <>
      <CTA />

      <Div
        tag="footer"
        p={{ t: { xs: "4rem", md: "4rem" }, b: { xs: "4rem", md: "4rem" } }}
        pos="relative"
        style={{ borderTop: "0.5px solid rgba(0,0,0,0.4)" }}
      >
        <Container>
          <Div m={{ b: "2rem" }}>
            <Row>
              {Object.keys(footerLinks).map((key, index) => {
                return (
                  <Col
                    size={{ xs: 5, md: 5 }}
                    offset={{ xs: 0, md: index === 0 && 1 }}
                  >
                    <Div
                      p={{ l: { md: "2.5rem" }, b: { xs: "1.5rem", md: "0" } }}
                      textColor="medium"
                    >
                      <Text
                        m={{ b: "1rem" }}
                        textColor="black"
                        textTransform="capitalize"
                        textWeight="500"
                      >
                        {key}
                      </Text>
                      {footerLinks[key].map((link, i) => {
                        if (link.anchor) {
                          return (
                            <Anchor
                              m={{ b: "0.5rem" }}
                              textColor="medium"
                              hoverTextColor="info800"
                              d="block"
                              href={link.link}
                              target="_blank"
                              textWeight="400"
                            >
                              {link.name}
                            </Anchor>
                          )
                        } else {
                          return (
                            <Link to={link.link}>
                              <Text
                                m={{ b: "0.5rem" }}
                                textColor="medium"
                                hoverTextColor="info800"
                              >
                                {link.name}
                              </Text>
                            </Link>
                          )
                        }
                      })}
                    </Div>
                  </Col>
                )
              })}
            </Row>
          </Div>

          <Div d="flex" align="center" justify="center" flexDir="column">
            <Text
              tag="h1"
              textWeight="400"
              textAlign="center"
              m={{ b: "1rem" }}
              style={{ fontSize: "15px" }}
            >
              <b>CÔNG TY CỔ PHẦN Công Nghệ UCALL</b> - Địa chỉ: Số 221 Trần
              Quang Khải, Phường Tân Định, Quận 1, Thành phố Hồ Chí Minh, Việt
              Nam
              <br />
              Giấy chứng nhận đăng ký doanh nghiệp số 0109690915 Do Phòng ĐKKD -
              Sở KHĐT thành phố Hà Nội cấp lần đầu ngày 01/07/2021
              <br />
              Email: contact@ucall.asia - Đường dây nóng: 84327 335 877
            </Text>
            {/*<Div d="flex" align="center" justify="center">*/}
            {/*  {mediaLinks.map((link, index) => (*/}
            {/*    <Anchor href={link.link} target="_blank">*/}
            {/*      <Icon*/}
            {/*        name={link.icon}*/}
            {/*        size="20px"*/}
            {/*        hoverColor="info800"*/}
            {/*        m={{ r: index !== mediaLinks.length - 1 && "1rem" }}*/}
            {/*        cursor="pointer"*/}
            {/*      />*/}
            {/*    </Anchor>*/}
            {/*  ))}*/}
            {/*  <a href="http://online.gov.vn/Home/WebDetails/88018">*/}
            {/*    <img*/}
            {/*      width={150}*/}
            {/*      alt=""*/}
            {/*      title=""*/}
            {/*      src="http://online.gov.vn/Content/EndUser/LogoCCDVSaleNoti/logoSaleNoti.png"*/}
            {/*    />*/}
            {/*  </a>*/}
            {/*</Div>*/}
          </Div>
        </Container>
      </Div>
    </>
  )
}

export default Footer
