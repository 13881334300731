import React from "react"
import { Container, Icon, Modal, Text } from "atomize"
import { PrimaryColor } from "./colors"
import LeadsForm from "./LeadsForm"

export const ModalSize = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} rounded="md" maxW="48rem">
      <Icon
        name="Cross"
        pos="absolute"
        top="1rem"
        right="1rem"
        size="16px"
        onClick={onClose}
        cursor="pointer"
      />

      <Container d="flex" flexDir={"column"}>
        <Text
          tag="h3"
          textWeight="700"
          textAlign="left"
          textSize="display5"
          fontFamily="secondary"
          textColor={PrimaryColor}
          m={{ b: "1rem", t: "2rem" }}
        >
          Liên hệ tư vấn
        </Text>
        <LeadsForm />
      </Container>
    </Modal>
  )
}
