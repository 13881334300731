import { Anchor, Button } from "atomize"
import firebase from "gatsby-plugin-firebase"
import React, { useState } from "react"
import { ModalSize } from "./formModal"

const CTA = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div
      style={{
        padding: "1rem",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "3rem",
      }}
    >
      <Button
        h="3rem"
        w={{ xs: "100%", sm: "32rem" }}
        bg="info700"
        hoverBg="info600"
        rounded="lg"
        onClick={() => {
          setShowModal(true)
          firebase.analytics().logEvent("open_contact_form")
        }}
      >
        Liên hệ tư vấn
      </Button>
      <Anchor
        w={{ xs: "100%", sm: "32rem" }}
        href="https://growth.ucall.vn/"
        onClick={() => {
          firebase.analytics().logEvent("tap_on_trai_nghiem_ngay")
        }}
        target="_blank"
      >
        <Button
          bg="success700"
          hoverBg="success700"
          textColor="white"
          rounded="lg"
          w={"100%"}
          m={{ t: "16px", b: "16px" }}
          style={{ letterSpacing: "-0.5px" }}
          h="3rem"
          onClick={() => {
            firebase
              .analytics()
              .logEvent("tap_on_trai_nghiem_ngay_bat_dong_san")
          }}
        >
          Trải Nghiệm Ngay
        </Button>
      </Anchor>
      <ModalSize isOpen={showModal} onClose={() => setShowModal(false)} />
    </div>
  )
}

export default CTA
