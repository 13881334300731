import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "./font.css"
// import "./wow.css"

import { StyleReset, ThemeProvider, Div } from "atomize"
import { Helmet } from "react-helmet"
import Delayed from "./delayed"
import Footer from "./homepage/Footer"

const theme = {
  fontFamily: {
    primary:
      '"Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    secondary:
      '"Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
    code: "'Fira Mono','Roboto Mono', monospace",
  },
  grid: {
    containerMaxWidth: {
      xl: "1184px",
    },
  },
  textSize: {
    size: {
      display3: "48px",
    },
    height: {
      display3: "56px",
    },
  },
  colors: {
    brandgray: "#F7F5F4",
    info700: "#2C39A0",
    info600: "#4F5ECF",
  },
}

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <ThemeProvider theme={theme}>
            <StyleReset />
            <Div tag="main" overflow="hidden">
              {children}
              <Footer />
              <a href="tel:+84327335877" className="call-to-us">
                <div className="call-to-us__label">Liên Hệ</div>
              </a>
            </Div>
          </ThemeProvider>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
